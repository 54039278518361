<template lang="html">
  <div class="ActivityMembersPage" :class="{'isUserSize':isUser}">
    <div class="ActivityMembersPage__Header">
      <div class="ActivityMembersPage__Header__info">
        <div class="ActivityMembersPage__Header__info--title">
          <img v-if="communityCurrentActivity.type=='WAT_SURVEY'" src="@/assets/img/opinionIcon.png">
          <img v-if="communityCurrentActivity.type=='EL_SURVEY'" src="@/assets/img/pollIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_TASK'" src="@/assets/img/blogIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_CHAT'" src="@/assets/img/blogIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_POLL'" src="@/assets/img/pollIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_OPINION'" src="@/assets/img/opinionIcon.png">
          <img v-if="communityCurrentActivity.type=='VIDEOCHAT'" src="@/assets/img/videoChatIcon.svg" class="videoChat-icon">
          <h1>{{communityCurrentActivity.title}}</h1>
        </div>
        <div class="ActivityMembersPage__Header__info--dates">
          <span class="status-circle active"></span>
          <p>{{$t('date_from_to2',{from:formatDateForTranslation(communityCurrentActivity.dateFrom), to:formatDateForTranslation(communityCurrentActivity.dateTo)})}}</p>
        </div>
      </div>
    </div>
    <div class="ActivityMembersPage__Filters" v-if="isClient">
      <div>
        <div class="ActivityMembersPage__Filters--tags">
          <span class="Filter__Name"><b>{{$t('tags')}}</b></span>
          <Tag
            v-for="(tag,i) in communityTags"
            :key="'tag'+i"
            :name="tag"
            :isActive="appliedFilters.tag.includes( tag )"
            v-on:add="handleAddTag(tag)"
            v-on:remove="handleRemoveTag(tag)"
          />
        </div>
        <div class="ActivityMembersPage__Filters--tags">
          <span class="Filter__Name"><b>{{$t('tags_public')}}</b></span>
          <Tag
            v-for="(tag,i) in communityPublicTags"
            :key="'tag'+i"
            :name="tag"
            :isActive="appliedFilters.publicTag.includes( tag )"
            :isPublic="true"
            v-on:add="handleAddPublicTag(tag)"
            v-on:remove="handleRemovePublicTag(tag)"
          />
        </div>
      </div>
      <div class="ActivityMembersPage__Filters--bar">
        <SearchBar v-on:key-up="applyFilters" v-model="appliedFilters.nickname"></SearchBar>
      </div>
    </div>
    <div class="ActivityMembersPage__Show" v-if="isClient">
      <div>
        <p class="members--length">{{$t('members_show_of',{membersNum:members.length,membersTotal:communityCurrentActivity.membersNum})}}</p>
        <hr>
        <p v-if="notParticipateMembersIds.length>0">{{$t('members_not_participated',{members:notParticipateMembersIds.length})}}
          <div class="ActivityMembersPage__Header__download" v-if="isClient">
            <button
          style="background: var(--primary-color)"
          v-on:click="remindAllMembers(notParticipateMembersIds)"
          v-if="isModerator && notParticipateMembersIds.length>0 && communityInfo.status != 'REVISION'"
        > {{$t('members_remind')}}({{ notParticipateMembersIds.length }})
      </button></div></p>
        <p v-if="notParticipateMembersIds.length==0">{{$t('members_all_participated')}}</p>
      </div>
    </div>
    <div class="ActivityMembersPage__Members">
      <MemberItem
        v-for="(participant, index) in members"
        :key="participant.identifier"
        v-if="index <= (members.length-1)"
        :allowRemind="isModerator && communityInfo.status != 'REVISION'"
        :currentUserId="userProfile.id"
        :hideGamification="communityInfo.hideGamification || (communityInfo.privateLabs && isUser)"
        v-on:remind-member="remindMember"
        :member="participant" />
      <!-- <div class="ActivityMembersPage__Members--Column">
        <MemberItem
          v-for="(participant, index) in members"
          v-if="index <= (members.length-1) / 2"
          :allowRemind="isClient"
          v-on:remind-member="remindMember"
          :member="participant" />
      </div>
      <div class="ActivityMembersPage__Members--Column">
        <MemberItem
          v-for="(participant, index) in members"
          v-if="index > (members.length-1) / 2"
          :allowRemind="isClient"
          v-on:remind-member="remindMember"
          :member="participant" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import store from '@/store';
import {
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS,
  COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT,
} from '@/store/community/activities.module';
import { COMMUNITY_FETCH_ONLINE_USERS } from '@/store/community/members.module';

import MemberItem from '@/components/MemberItem.vue';
import Tag from '~/components/Tag';
import SearchBar from '~/components/SearchBar/SearchBar.vue';
export default {
  name: 'ActivityMembersPage',

  components: {
    MemberItem,
    Tag,
    SearchBar,
  },
  computed: {
    ...mapGetters([
      'communityCurrentActivity',
      'communityCurrentActivityParticipants',
      'communityTags',
      'communityPublicTags',
      'userProfile',
      'isClient',
      'isModerator',
      'isUser',
      'theme',
      'onlineUsers',
      'communityInfo',
    ]),
  },

  data(){
    return{
      members: [],
      allMembers:[],
      notParticipateMembersIds:[],
      appliedFilters: {
        order: 'recent',
        tag: [],
        publicTag: [],
        nickName: null,
      },
    }
  },

  methods:{
    fetchMembers: async function(e){
      await store.dispatch(e, {
        activityId: this.$route.params.id,
      })
      .then((data) => {
        this.members = data
        this.allMembers = data
        this.notParticipateMembersIds = data.filter(function(item){
          if (!item.hastParticipated) {
            return item
          }
        });
        this.fetchOnlineUsers()
      })
    },

    handleAddTag(tag) {
      if ( !this.appliedFilters.tag.includes( tag ) ) {
        this.appliedFilters.tag.push( tag );
      }
      this.applyFilters();
    },

    handleRemoveTag(tag) {
      this.appliedFilters.tag = this.appliedFilters.tag.filter( t => t !== tag )
      this.applyFilters();
    },

    handleAddPublicTag(tag) {
      if ( !this.appliedFilters.publicTag.includes( tag ) ) {
        this.appliedFilters.publicTag.push( tag );
      }
      this.applyFilters();
    },

    handleRemovePublicTag(tag) {
      this.appliedFilters.publicTag = this.appliedFilters.publicTag.filter( t => t !== tag )
      this.applyFilters();
    },

    async applyFilters(e,i) {
      if (i != undefined) {
        this.appliedFilters.nickname = i
      }
      await store.dispatch(COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL, {
        order: this.appliedFilters.order,
        filter: this.appliedFilters.tag.join(','),
        publicFilter: this.appliedFilters.publicTag.join(','),
        nickname: this.appliedFilters.nickname,
        activityId: this.$route.params.id,
      })
      .then((data) => {
        this.members = data.communityMembers
        this.notParticipateMembersIds = []
        if (data.communityMembers.length > 0) {
          this.notParticipateMembersIds = data.communityMembers.filter(function(item){
            if (!item.hastParticipated) {
              return item
            }
          });
        }
        this.fetchOnlineUsers()
      })
    },

    remindMember: async function(member, type){
      await store.dispatch(COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT, {
        activityId: this.$route.params.id,
        memberId:member.identifier,
      })
      if (type == "ALL") {
        Swal.fire(this.$t('msg_members_remind_sent'))
      }
      else{
        Swal.fire(this.$t('msg_members_remind_sent_member',{member:member.nickName}))
      }
    },

    remindAllMembers: async function(members){
      for (let i = 0; i < members.length; i++) {
        this.remindMember(members[i],"ALL")
      }
    },
    fetchOnlineUsers(){
        for(let m of this.members){
          m.online = this.onlineUsers.testersOnlineCondensed.includes(m.identifier)
        }
        for(let m of this.allMembers){
          m.online = this.onlineUsers.testersOnlineCondensed.includes(m.identifier)
        }
    },
    formatDateForTranslation(date){
      if (date == undefined) {
        return "-"
      }
      return moment(date).format("DD/MM HH:mm");
    },
  },

  async created() {
    await this.fetchMembers(this.isClient ? COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL : COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS);
    setInterval( (  ) => {
      this.fetchOnlineUsers()
    }, 10000 );
  },
}
</script>

<style lang="scss">
.ActivityMembersPage__Filters--bar{
  .SearchBar {
    input, input::placeholder {
      font-size: .8rem !important;
      padding-left: 10px !important;
    }
    input::placeholder {
      padding-left: 0px !important;
    }
  }
}
</style>

<style scoped lang="scss">
.ActivityMembersPage{
  width: 100%;
  max-height: calc(100vh - 100px);
  margin-top: -37px;
  overflow-y: auto;
  overflow-x: hidden;
  &.isUserSize{
    max-height: calc(100vh - 104px);
    margin-top:0;
  }
  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: var(--primary-color);
  }
  &__Header{
    background: white;
    padding: 20px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and ( max-width: 768px ) {
      flex-direction: column;
    }
    &__info{
      display: flex;
      flex-direction: column;
      .videoChat-icon{
        width: 36px;
        padding: 5px;
      }
      &--title{
        display: flex;
        align-items: center;
        img{
          width: 40px;
        }
        h1{
          font-size: 33px;
          font-weight: 500;
        }
      }
      &--dates{
        display: flex;
        align-items: baseline;
        color: #959494;
        font-size: .8rem;
        .status-circle{
          margin: 0 15px!important;
        }
      }
    }
    &__download{
      @media screen and ( max-width: 768px ) {
        padding-top: 10px;
      }
      button{
        border-radius: 20px;
        border: none;
        font-size: 14px;
        padding: 10px 20px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
        margin-left: 20px;
      }
    }
  }
  &__Filters{
    display: flex;
    background: white;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    @media screen and ( max-width: 768px ) {
      flex-direction: column;
      padding: 10px;
    }
    &--tags{
      width: 100%;
      font-size: .8rem;
      padding: 10px;
      .Tag {
        margin-left: 5px;
        margin-top: 5px;
      }
    }
    &--bar{
      width: 40%;
      border-left: 1px solid #f1f1f1;
      @media screen and ( max-width: 768px ) {
        width: 100%;
        border-left: none;
      }
    }
  }
  &__Show{
    background: #f9f9f9;
    border-top: 1px solid #f1f1f1;
    padding: 10px 20px;
    display: flex;
    font-size: .8rem !important;
    div{
      display: flex;
      align-items: center;
      hr{
        margin: 0 10px;
        width: 1px;
        height:15px;
      }
      .members{
        &--length{
          color: #b3b3b3;
        }
        &--remind{
          color: #f6c07e;
          cursor: pointer;
        }
      }
    }
  }
  &__Members{
    display: flex;
    flex-wrap: wrap;
    &--Column{
      width: 100%;
      border:1px solid #f1f1f1;
      .MemberItem{
        border-bottom: 1px solid #f1f1f1;
        min-height: 125px;
      }
    }
  }
}
</style>
